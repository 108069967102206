(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/register-sw/assets/javascripts/index.js') >= 0) return;  svs.modules.push('/components/core/register-sw/assets/javascripts/index.js');

if ('serviceWorker' in navigator) {
  var log = svs.core.log.getLogger('component:register-sw');
  navigator.serviceWorker.register('/pwa-sw.js', { scope: '/' }).then(function() {
  })
    .catch(function(e) { 
      log.warn('Service worker registration failed:', e);
    });
}


 })(window);